export const QR_DIRECTIVES = {
	"DIRck1ef": { game: "tlaok", "slug": "call-fog" },
	"DIRq0rzs": { game: "tlaok", "slug": "divine-sanction" },
	"DIRj7ei8": { game: "tlaok", "slug": "earth-to-mud" },
	"DIR831zz": { game: "tlaok", "slug": "fervor" },
	"DIR6dz6o": { game: "tlaok", "slug": "fire-dart" },
	"DIRic5fn": { game: "tlaok", "slug": "guide" },
	"DIRd6bf0": { game: "tlaok", "slug": "heavenly-blessing" },
	"DIRnqn2f": { game: "tlaok", "slug": "holy-fire" },
	"DIRq21g1": { game: "tlaok", "slug": "kindle-courage" },
	"DIRjza2v": { game: "tlaok", "slug": "ninuah-s-tears" },
	"DIRpa5pg": { game: "tlaok", "slug": "saint-s-favor" },
	"DIRjwe63": { game: "tlaok", "slug": "seeking-winds" },
	"DIR17q9u": { game: "tlaok", "slug": "stone-spikes" },
	"DIRa8rqq": { game: "tlaok", "slug": "accelerated-hibernation" },
	"DIRbkyv1": { game: "tlaok", "slug": "catalytic-rupture" },
	"DIRyd7k7": { game: "tlaok", "slug": "essence-transfer" },
	"DIRxkg9u": { game: "tlaok", "slug": "grant-virulence" },
	"DIRtwmd4": { game: "tlaok", "slug": "harvest-essence" },
	"DIRm1id8": { game: "tlaok", "slug": "induced-vigor" },
	"DIRjptu1": { game: "tlaok", "slug": "pheromantic-compulsion" },
	"DIRkyi7s": { game: "tlaok", "slug": "pheromantic-drive" },
	"DIR7yseb": { game: "tlaok", "slug": "siphon-strength" },
	"DIRkds8g": { game: "tlaok", "slug": "unstable-enhancement" },
	"DIRabnp7": { game: "tlaok", "slug": "broken-ground" },
	"DIRm3r6m": { game: "tlaok", "slug": "coruscation" },
	"DIRswvhg": { game: "tlaok", "slug": "dismay" },
	"DIRe1l7h": { game: "tlaok", "slug": "eruption" },
	"DIRw5a4r": { game: "tlaok", "slug": "fireball" },
	"DIRpuitz": { game: "tlaok", "slug": "flame-wall" },
	"DIRoo95j": { game: "tlaok", "slug": "hone-blades" },
	"DIR36ifi": { game: "tlaok", "slug": "magmatic-seep" },
	"DIRwknhj": { game: "tlaok", "slug": "pyroclast" },
	"DIR3gebc": { game: "tlaok", "slug": "resolve" },
	"DIRxlql9": { game: "tlaok", "slug": "rock-shaping" },
	"DIRn3z0e": { game: "tlaok", "slug": "roots-of-stone" },
	"DIR910p6": { game: "tlaok", "slug": "temper-plate" },
	"DIR7qtu7": { game: "tlaok", "slug": "unmake-armor" },
	"DIRs6awm": { game: "tlaok", "slug": "aspect-of-fenrir" },
	"DIRtff8n": { game: "tlaok", "slug": "blurred-vision" },
	"DIRvwgbv": { game: "tlaok", "slug": "dominate" },
	"DIRlvs4l": { game: "tlaok", "slug": "encase" },
	"DIRoua3i": { game: "tlaok", "slug": "enrage" },
	"DIRxqygl": { game: "tlaok", "slug": "fruit-of-the-golden-tree" },
	"DIRpqit6": { game: "tlaok", "slug": "glory-of-valhalla" },
	"DIR61ikz": { game: "tlaok", "slug": "hela-s-caress" },
	"DIRoewyw": { game: "tlaok", "slug": "ice-armor" },
	"DIRbkn7t": { game: "tlaok", "slug": "mist-weave" },
	"DIRdq87h": { game: "tlaok", "slug": "reshape-destiny" },
	"DIRdxrg1": { game: "tlaok", "slug": "rime-storm" },
	"DIRm98kw": { game: "tlaok", "slug": "warbringer-s-light" },
	"DIRug13v": { game: "tlaok", "slug": "blackflame-coruscation" },
	"DIR8drk8": { game: "tlaok", "slug": "blasphemous-power" },
	"DIRptehi": { game: "tlaok", "slug": "dark-immolation" },
	"DIRlx3bk": { game: "tlaok", "slug": "dark-supplication" },
	"DIRr4vig": { game: "tlaok", "slug": "drain-will" },
	"DIReljwi": { game: "tlaok", "slug": "hazlia-s-touch" },
	"DIR72wby": { game: "tlaok", "slug": "immolation" },
	"DIRcwept": { game: "tlaok", "slug": "insanity" },
	"DIRn9cbn": { game: "tlaok", "slug": "translocation" },
	"DIRyfwbt": { game: "tlaok", "slug": "unholy-baptism" },
	"DIRkwvow": { game: "tlaok", "slug": "cacophony" },
	"DIR315tv": { game: "tlaok", "slug": "crescendo" },
	"DIRosyib": { game: "tlaok", "slug": "dissonance" },
	"DIR6lq89": { game: "fb", "slug": "a-fiery-end" },
	"DIRy8yzr": { game: "fb", "slug": "coruscation" },
	"DIRkmyk3": { game: "fb", "slug": "earth-s-grasp" },
	"DIRqkho2": { game: "fb", "slug": "ferric-expansion" },
	"DIRzjfef": { game: "fb", "slug": "fuelled-by-the-furnace" },
	"DIRz2gm1": { game: "fb", "slug": "living-metal" },
	"DIRcc4kf": { game: "fb", "slug": "moaghm-dorh" },
	"DIRk7uom": { game: "fb", "slug": "oxidize" },
	"DIRgrged": { game: "fb", "slug": "temper-blades" },
	"DIRg4xi7": { game: "fb", "slug": "blinding-light" },
	"DIR8vy3g": { game: "fb", "slug": "brine-erosion" },
	"DIRotles": { game: "fb", "slug": "encase" },
	"DIRm1li9": { game: "fb", "slug": "glory-to-valhalla" },
	"DIRf0oiy": { game: "fb", "slug": "ice-armor" },
	"DIRu8fkq": { game: "fb", "slug": "mist-weave" },
	"DIRvf36i": { game: "fb", "slug": "prove-yourself-worthy" },
	"DIR5lwol": { game: "fb", "slug": "reshape-destiny" },
	"DIRu39qp": { game: "fb", "slug": "rime-storm" },
	"DIRr53fj": { game: "fb", "slug": "spirit-of-fenrir" },
	"DIRskk2l": { game: "fb", "slug": "valhalla-s-caress" },
	"DIRuonwg": { game: "fb", "slug": "ashes-to-ashes" },
	"DIRjv644": { game: "fb", "slug": "aura-of-malice" },
	"DIRpn2sj": { game: "fb", "slug": "blasphemous-miasma" },
	"DIR5ck11": { game: "fb", "slug": "blasphemous-prayer" },
	"DIRts4r7": { game: "fb", "slug": "duty-bound" },
	"DIRu34g6": { game: "fb", "slug": "glimmers-of-a-golden-age" },
	"DIR19eb4": { game: "fb", "slug": "madness" },
	"DIR3rgsc": { game: "fb", "slug": "nekrosis" },
	"DIRhjhxy": { game: "fb", "slug": "past-echoes" },
	"DIRdy0tz": { game: "fb", "slug": "pyrosis" },
	"DIR99l3q": { game: "fb", "slug": "resonating-essence" },
	"DIRnm9fo": { game: "fb", "slug": "unholy-visage" },
	"DIRfohpc": { game: "fb", "slug": "deafen" },
	"DIR6srbr": { game: "fb", "slug": "disruption" },
	"DIR35g72": { game: "fb", "slug": "embolden" },
	"DIRt5fcq": { game: "fb", "slug": "aggressive-compulsion" },
	"DIRvrk99": { game: "fb", "slug": "amplified-biogenics" },
	"DIR33ohv": { game: "fb", "slug": "catalytic-strength" },
	"DIRui1w7": { game: "fb", "slug": "entropic-transfer" },
	"DIR7cg7p": { game: "fb", "slug": "establish-perimeter" },
	"DIRa51mh": { game: "fb", "slug": "heightened-senses" },
	"DIRzfegp": { game: "fb", "slug": "mend-flesh" },
	"DIR6lf3g": { game: "fb", "slug": "pack-insticts" },
	"DIRz2lgo": { game: "fb", "slug": "pheromone-burst" },
	"DIR4p5a4": { game: "fb", "slug": "rapid-revitalization" },
	"DIRyxpm1": { game: "fb", "slug": "recuperate" },
	"DIRhd8dh": { game: "fb", "slug": "territorial-instincts" },
	"DIR28ktd": { game: "fb", "slug": "virulence" },
	"DIR0gzwf": { game: "fb", "slug": "aetheric-interference" },
	"DIR6xjnv": { game: "fb", "slug": "flame-burst" },
	"DIRt6m9z": { game: "fb", "slug": "ninuah-s-caress" },
	"DIR281q5": { game: "fb", "slug": "roiling-earth" },
	"DIR6rg7c": { game: "fb", "slug": "burst-of-faith" },
	"DIRgrv70": { game: "fb", "slug": "divine-purpose" },
	"DIR5yu9v": { game: "fb", "slug": "fortitude-of-faith" },
	"DIRu65kb": { game: "tlaok", "slug": "crucible-s-fire" },
	"DIRj3l8m": { game: "tlaok", "slug": "molon-labe" },
	"DIRwg7dx": { game: "tlaok", "slug": "quench-blades" },
	"DIRu1e08": { game: "tlaok", "slug": "clockwork-parade" },
	"DIRsfrb1": { game: "tlaok", "slug": "aggression-directive" },
	"DIR8p83t": { game: "tlaok", "slug": "temper-resolve" },
	"DIR7xpkk": { game: "tlaok", "slug": "trident-strike" },
	"DIRl98fd": { game: "tlaok", "slug": "iron-stride" },
	"DIRq6yth": { game: "tlaok", "slug": "othismos" },
	"DIRyghfi": { game: "fb", "slug": "smoldering-in-ashes" },
	"DIR1edtk": { game: "fb", "slug": "piercing-heat" },
	"DIRiw5gr": { game: "fb", "slug": "damping-force" },
	"DIR7e5nk": { game: "fb", "slug": "resist" },
	"DIR7exso": { game: "fb", "slug": "magnetic-balance" },
	"DIRtdlsy": { game: "fb", "slug": "shock-and-steel" },
	"DIR9ty8c": { game: "fb", "slug": "structural-rearrangement" },
	"DIR8z8er": { game: "fb", "slug": "synchronized-formation" },
	"DIR429ic": { game: "fb", "slug": "mobility" },
	"DIR6efog": { game: "fb", "slug": "liquid-fire" },
	"DIRe8ib9": { game: "tlaok", "slug": "torque" },
	"DIRpd5we": { game: "tlaok", "slug": "rancor" },
	"DIRjvd4e": { game: "tlaok", "slug": "fear" },
	"DIRj87ew": { game: "tlaok", "slug": "hoarfrost" },
	"DIRdmm09": { game: "tlaok", "slug": "undying-devotion" },
	"DIRh9mdl": { game: "tlaok", "slug": "benediction-of-the-black-sun" },
	"DIR51l0g": { game: "tlaok", "slug": "minor-elemental-missile" },
	"DIRmpdcv": { game: "tlaok", "slug": "adrenal-surge" },
	"DIRxbt8w": { game: "tlaok", "slug": "subdermal-keratin-induction" },
	"DIRo8i3r": { game: "tlaok", "slug": "discordance" },
	"DIRx8qa4": { game: "tlaok", "slug": "resonance" },
	"DIR97lo8": { game: "tlaok", "slug": "conflagration" },
	"DIRxnnt3": { game: "tlaok", "slug": "fiery-dominion" },
	"DIRddnz5": { game: "tlaok", "slug": "intrusive-thoughts" },
	"DIRp3oya": { game: "tlaok", "slug": "spiteful-winds" },
	"DIRpu489": { game: "tlaok", "slug": "incite-rage" },
	"DIR60iu9": { game: "tlaok", "slug": "sayf" },
	"DIR6wbec": { game: "tlaok", "slug": "far-sight" },
	"DIRpq8h8": { game: "tlaok", "slug": "entropic-shield" },
};
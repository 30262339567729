import { Button, Stack, Typography, Link as MuiLink } from "@mui/material";
import { Link, useNavigation } from "react-router-dom";
import FrameBox from "../../ui/containers/FrameBox";
import { Box, styled } from "@mui/system";
import CenteredContentBox from "../../ui/containers/CenteredContentBox";
import { ReactComponent as Separator }
	from "../../assets/decorative/separator-hor.svg";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { useDispatch } from "react-redux";
import { openScanner, openListSetup } from "../../store/utils-slice";
import useGameSettings from "../../hooks/use-game-settings";
import Loading from "../../ui/Loading";
import RippedBox from "../../ui/containers/RippedBox";
import AppPromo from "../AppPromo";


const GameLogo = styled("img")(({ theme }) => ({
	height: "90px",
	[theme.breakpoints.only("xs")]: {
		height: "70px",
	},
}));


const Line = styled(Separator)(({ theme }) => ({
	height: "40px",
	fill: "currentColor",
	padding: theme.spacing(0, 0, 3, 0),
}));


function MainGameMenu({ game }) {
	const { slug, name } = game;
	const { getGameSettings } = useGameSettings();
	const { logo } = getGameSettings(slug);
	const dispatch = useDispatch();
	const { state } = useNavigation();

	function handleNewList() {
		dispatch(openListSetup());
	}

	function handleScannerOpen() {
		dispatch(openScanner());
	}

	if (state === "loading") {
		return (
			<CenteredContentBox>
				<Loading />
			</CenteredContentBox>
		);
	}

	return (
		<CenteredContentBox>
			<Stack spacing={2} alignItems="center">
				<FrameBox
					frameStyle="frame1"
					bgcolor="darkBgr.main"
					color="darkBgr.contrastText"
				>
					<Stack spacing={2} m={{ xs: 5, sm: 9 }}>
						<GameLogo src={logo} alt={name} />
						<Line />
						<Button
							component={Link}
							to="factions"
							variant="contained"
							size="large"
						>
							Browse Factions
						</Button>
						<Button variant="contained" size="large" onClick={handleNewList}>
							Prepare for War
						</Button>
						<Button
							variant="contained"
							size="large"
							onClick={handleScannerOpen}
							startIcon={<QrCodeScannerIcon />}
						>
							Scan Card
						</Button>
					</Stack>
				</FrameBox>
				<Box pt={4}>
					<AppPromo />
				</Box>
			</Stack>
		</CenteredContentBox>
	);
}


export default MainGameMenu;
import { getNode } from "../../lib/list";

const TLAOK_TARGETS = {
	"LIST": (list) => {
		return [list];
	},
	"ALL_ENTRIES": (list, origin, params) => {
		const { children: warbands } = list;
		const entries = [];
		warbands.forEach(w => {
			if (params) {
				entries.push(...w.children.filter(c => params.types.includes(c.listEntry.type)));
			} else {
				entries.push(...w.children);
			}
		});
		return entries;
	},
	"ALL_REGIMENTS": (list) => {
		const { children: warbands } = list;
		const regiments = [];
		warbands.forEach(w => {
			const types = ["mainstay", "restricted"];
			regiments.push(...w.children.filter(c => types.includes(c.type)));
		});
		return regiments;
	},
	"WARLORD_REGIMENTS": (list) => {
		const { children: warbands } = list;
		const regiments = [];
		warbands.forEach(w => {
			const character = w.children.find(c => c.type === "character")
			if (character.listEntry.isWarlord) {
				const types = ["mainstay", "restricted"];
				regiments.push(...w.children.filter(c => types.includes(c.type)));
			}
		});
		return regiments;
	},
	"ALL_CHARACTERS": (list) => {
		const { children: warbands } = list;
		const characters = [];
		warbands.forEach(w => {
			characters.push(...w.children.filter(c => c.type === "character"));
		});
		return characters;
	},
	"ALL_OTHER_CHARACTERS": (list, origin) => {
		const { children: warbands } = list;
		const characters = [];
		warbands.forEach(w => {
			const character = w.children.find(c => c.type === "character" && c.id !== origin.id);
			if (character) characters.push(character);
		});
		return characters;
	},
	"WARBAND": (list, origin) => {
		return [origin.parent];
	},
	"WARBAND_ENTRIES": (list, origin) => {
		const warband = origin.parent;
		return warband.children;
	},
	"REGIMENTS_OF": (list, origin, params) => {
		const { children: warbands } = list;
		const { characterSlugs = [] } = params;
		const regiments = [];

		for (const w of warbands) {
			const character = w.children.find(c => c.type === "character");
			if (characterSlugs.includes(character.entrySlug)) {
				regiments.push(...w.children.filter(c => c.type !== "character"));
			}
		}

		return regiments;
	},
	"WARBAND_REGIMENTS": (list, origin, params) => {
		const warband = origin.parent;
		const regiments = warband.children.filter(c => c.type !== "character");

		const slugFilter = params?.filters?.slugs || [];
		const typeFilter = params?.filters?.types || [];

		if (slugFilter.length > 0) {
			return regiments.filter(r => slugFilter.includes(r.entrySlug));
		} else if (typeFilter.length > 0) {
			return regiments.filter(r => typeFilter.includes(r.listEntry.type));
		} else {
			return regiments;
		}
	},
	"SELF": (list, origin) => {
		return [origin];
	},
	"SELF_LINK": (list, origin, params) => {
		const { linkedNodes = [] } = origin;
		const { slugs = [] } = params;
		const targets = [];

		slugs.forEach(s => {
			const paths = linkedNodes[s] || [];
			paths.forEach(p => {
				targets.push(getNode(list, p));
			});
		});

		return targets;
	},
}


export default TLAOK_TARGETS;
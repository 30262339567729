const TLAOK_RULES = {
	"WARBAND_CONSTITUTION": (node) => {
		const { alerts, children } = node;
		const childrenSlugs = children.map(c => c.entrySlug);
		const mainstayCount = children.filter(r => r.type === "mainstay").length;
		const restrictedCount = children.filter(r => r.type === "restricted").length;;
		const regimentTypes = children.filter(r => ["mainstay", "restricted"].includes(r.type)).filter(r => !["kheres", "moroi", "selinoi-hunters", "war-chariots", "hunting-pack", "ghols"].includes(r.listEntry.slug)).map(r => r.listEntry.type);
		const characterNode = children.find(c => c.type === "character");
		const { listEntry } = characterNode;
		const { type: characterType } = listEntry;
		const allMainstay = (listEntry.warband.mainstay || []).map(e => e.slug);
		const allRestricted = (listEntry.warband.restricted || []).map(e => e.slug);

		if (listEntry.slug === "fallen-divinity") {
			if (mainstayCount + restrictedCount > 2) {
				alerts.push({ message: "A Fallen Divinity can only have up to two Regiments in its Warband." });
			}
		} else {
			if (["maharajah", "raj", "sardar", "sorcerer"].includes(listEntry.slug)) {
				const canHaveFifth = childrenSlugs.filter(x => ["rajakur", "dhanurveda-archers"].includes(x)).length > 0;
				if (mainstayCount + restrictedCount > 5 && canHaveFifth) {
					alerts.push({ message: "Up to five Regiments can be added to this Warband." });
				} else if (mainstayCount + restrictedCount > 4 && !canHaveFifth) {
					alerts.push({ message: "Up to four Regiments can be added to the Warband." });
				}
			} else if (mainstayCount + restrictedCount > 4) {
				alerts.push({ message: "Up to four Regiments can be added to the Warband." });
			}

			if (restrictedCount > mainstayCount) {
				alerts.push({ message: "Restricted Regiment choices must not exceed the number of Mainstay." });
			}

			if (restrictedCount > 2) {
				alerts.push({ message: "Up to two Restricted Regiments can be added to the Warband." });
			}

			if (listEntry.slug === "ardent-kerawegh" && children.filter(c => c.entrySlug === "flame-berserkers").length > 2) {
				alerts.push({ message: "Up to two Regiments of Flame Berserkers can be added to this Warband." });
			}

			if (listEntry.slug === "mounted-strategos" && children.filter(c => c.entrySlug === "kataphraktoi").length > 2) {
				alerts.push({ message: "Up to two Regiments of Kataphraktoi can be added to this Warband." });
			}

			if (listEntry.slug === "eidolon" && children.filter(c => c.entrySlug === "inquisitors").length > 2) {
				alerts.push({ message: "Up to two Regiments of Inquisitors can be added to this Warband." });
			}
		}

		children.filter(c => c.type === "mainstay").forEach(c => {
			if (!allMainstay.includes(c.listEntry.slug) && !characterNode.selections.includes("sanctified-labaron")) {
				alerts.push({ message: `${c.listEntry.name} is not part of this Character's Mainstay options.` });
			}
		});
		children.filter(c => c.type === "restricted").forEach(c => {
			if (!allRestricted.includes(c.listEntry.slug) && !characterNode.selections.includes("sanctified-labaron")) {
				alerts.push({ message: `${c.listEntry.name} is not part of this Character's Restricted options.` });
			}
		});

		const countDrumBeast = children.filter(c => c.entrySlug === "drum-beast").length;
		if (countDrumBeast > 1) {
			alerts.push({ message: "Only one Drum Beast may be purchased per Warband." });
		}

		if (characterNode.entrySlug === "vargyr-lord" && children.filter(c => c.entrySlug === "fenr-beastpack").length > 2) {
			alerts.push({ message: "Vargyr Lord may only include up to two Regiments of Fenr Beastpack in its Warband." });
		}

		if (
			characterType !== "Monster" &&
			!characterNode.selections.includes("hellbringer-sorcerer") &&
			!characterNode.selections.includes("bergont-raegh") &&
			!characterNode.selections.includes("apex-master") &&
			!characterNode.selections.includes("lord-of-all-they-see") &&
			!characterNode.selections.includes("tontorr-rider") &&
			!characterNode.selections.includes("ferric-throne") &&
			!(characterNode.entrySlug === "eidolon" && (regimentTypes.includes("Brute") || regimentTypes.includes("Infantry"))) &&
			!(characterNode.entrySlug === "vargyr-lord" && (regimentTypes.includes("Brute") || regimentTypes.includes("Cavalry"))) &&
			!regimentTypes.includes(characterType)
		) {
			alerts.push({ message: "Warband must inluce a Regiment the Character is allowed to join." });
		}
	},
	"THUNDER_CHIEFTAIN_WARBAND": (node) => {
		const { alerts, children } = node;
		const ridersCount = children.filter(c => c.entrySlug === "thunder-riders").length;

		if (ridersCount > 2) {
			alerts.push({ message: "This Warband may not contain more than 2 Regiments of Thunder Riders." })
		}
	},
	"LIMIT_OPTIONS": (node) => {
		const { listEntry, alerts, linkedNodes } = node;
		if (listEntry) {
			const { options, entrySelectors } = listEntry;
			if (options?.groups?.length) {
				for (const g of options.groups) {
					if (g.active === false) continue;

					let count = 0;
					if (g.groups) {
						count = g.groups.reduce((prev, s) => prev + s.orphans.filter(o => o.selected).length, 0);
					} else {
						count = g.orphans.filter(o => o.selected).length;
					}

					if (count > g.maxOptions) {
						alerts.push({ message: `Selected ${g.name} are over the maximum (${g.maxOptions}).` });
					}
					if (count < g.minOptions) {
						alerts.push({ message: `Selected ${g.name} are below the minimum (${g.minOptions}).` });
					}
				}
			}

			if (entrySelectors) {
				for (const s of entrySelectors) {
					const { active, minOptions, name, slug } = s;
					if (active === false) continue;
					if (!linkedNodes[slug]?.length) {
						alerts.push({ message: `${name} requires a valid target.` });
					}
				}
			}
		}
	},
	"CHARACTER": (node, params, faction) => {
		const { listEntry: character, alerts, selections } = node;
		// if (character.slug === "fallen-divinity") return;

		const { options, tagGroups, profiles, type } = character;
		const itemCategories = ["heirlooms", "artefacts", "trove-finds", "treasures", "relics", "bestowed-relics", "patron-s-gifts"];

		const group = options.groups.find(g => itemCategories.includes(g.slug));

		let banners = 0;
		let weapons = 0;
		let talismans = 0;
		let armors = 0;
		let arcane = 0;

		if (group) {
			const bannersGroup = group.groups?.find(g => g.slug === "banners")
			if (bannersGroup) {
				banners = bannersGroup.orphans.filter(o => o.selected).length
			}

			const weaponsGroup = group.groups?.find(g => g.slug === "weapons")
			if (weaponsGroup) {
				weapons = weaponsGroup.orphans.filter(o => o.selected).length
			}

			const armorsGroup = group.groups?.find(g => g.slug === "armors")
			if (armorsGroup) {
				armors = armorsGroup.orphans.filter(o => o.selected).length
			}

			const talismansGroup = group.groups?.find(g => g.slug === "talismans")
			if (talismansGroup) {
				talismans = talismansGroup.orphans.filter(o => o.selected).length
			}

			const arcaneGroup = group.groups?.find(g => g.slug === "arcane")
			if (arcaneGroup) {
				arcane = arcaneGroup.orphans.filter(o => o.selected).length
			}
		}

		if (banners > 1 || weapons > 1 || armors > 1 || talismans > 1 || arcane > 1) {
			alerts.push({ message: `Selected ${group.name} must belong in different categories` });
		}


		let isPriest = false;
		let isWizard = false;
		let isRider = false;
		const tagGroup = tagGroups?.find(g => g.slug === "special-rules") || profiles?.[0]?.tagGroups?.find(g => g.slug === "special-rules");
		if (tagGroup) {
			isPriest = !!tagGroup.tags.find(t => t.slug === "priest");
			isWizard = !!tagGroup.tags.find(t => t.slug === "wizard");
			isRider = !!tagGroup.tags.find(t => t.slug === "rider");
		}

		if (arcane > 0 && !isPriest && !isWizard) {
			alerts.push({ message: `Arcane ${group.name} are not allowed to Characters without the Priest or Wizard Special Rules.` });
		}

		if (armors > 0 && isWizard) {
			alerts.push({ message: `Armor ${group.name} are not allowed to Characters with the Wizard Special Rule.` });
		}

		if (armors > 0 && isPriest && faction.slug === "the-old-dominion") {
			alerts.push({ message: `Armor ${group.name} are not allowed to Characters with the Priest Special Rule.` });
		}

		if (banners > 0 && type !== "Infantry") {
			alerts.push({ message: "Only Infantry Character Stands may purchase a Banner Character Upgrade." });
		}
		if (banners > 0 && isRider) {
			alerts.push({ message: "Characters with a Banner Character Upgrade cannot be attached to a Monster Regiment." });
		}

		// MASTERIES

		if (faction.slug === "sorcerer-kings") {
			if (selections.includes("prince-of-the-setting-moon") && !character.isWarlord) {
				alerts.push({ message: "Prince of the Setting Moon requires the Character to be a Warlord." });
			}
			if (selections.includes("recorder-of-all-deeds") && !character.isWarlord) {
				alerts.push({ message: "Recorder of all Deeds requires the Character to be a Warlord." });
			}
			if (selections.includes("vizier-of-the-morning-star") && !character.isWarlord) {
				alerts.push({ message: "Vizier of the Morning Star requires the Character to be a Warlord." });
			}
		} else if (faction.slug === "dweghom") {
			if (selections.includes("hellbringer-sorcerer") && selections.filter(s => ["fire-school", "magma-school"].includes(s)).length <= 0) {
				alerts.push({ message: "Hellbringer Sorcerer requires School of Fire or Magma." });
			}
		}

		// TODO:  Create a generic CLASHES_WITH rule
		// MUTATIONS
		if (selections.includes("avatar-projection") && selections.includes("cloned-redundancy")) {
			alerts.push({ message: "Avatar Projection and Cloned Redundacy cannot be combined." });
		}
		if (selections.includes("avatar-projection") && selections.includes("master-of-flesh")) {
			alerts.push({ message: "Master of Flesh requires Infantry Character." });
		}
		if (selections.includes("marksman-variant") && selections.includes("infiltrator-variant")) {
			alerts.push({ message: "Marksman Variant and Infiltrator Variant cannot be combined." });
		}
		if (selections.includes("burrowing-parasites") && selections.includes("biotic-hive")) {
			alerts.push({ message: "Burrowing Parasites and Biotic Hive cannot be combined." });
		}

		// ARTEFACTS
		const hasOmgorah = selections.includes("brood-of-omgorah");
		const hasBrontoskalp = selections.includes("brontoskalp");
		const hasApex = selections.includes("apex-master");
		const hasTontorr = selections.includes("tontorr-rider");

		if (hasApex && hasOmgorah) {
			alerts.push({ message: "Apex Master and Brood of Omgorah cannot be combined." });
		}
		if (hasTontorr && hasOmgorah) {
			alerts.push({ message: "Tontorr Rider and Brood of Omgorah cannot be combined." });
		}
		if (hasTontorr && hasBrontoskalp) {
			alerts.push({ message: "Tontorr Rider and Brontoskalp cannot be combined." });
		}

		// WARLORD
		if (selections.includes("warlord") && selections.includes("dynastic-alliances-warlord")) {
			alerts.push({ message: "Only one type of Warlord can be selected." });
		}
	},
	"ADDITIONAL_SPELLS": (node) => {
		const { alerts, selections } = node;

		if (
			(selections.includes("school-of-fire") && (selections.includes("kindle-courage") || selections.includes("fire-dart"))) ||
			(selections.includes("school-of-water") && (selections.includes("ninuah-s-tears") || selections.includes("call-fog"))) ||
			(selections.includes("school-of-earth") && (selections.includes("earth-to-mud") || selections.includes("stone-spikes"))) ||
			(selections.includes("school-of-air") && (selections.includes("seeking-winds") || selections.includes("guide")))
		) {
			alerts.push({ message: "Additional spells must come from a different School." });
		}
	},
	"REQUIRES_WARLORD": (node, params, faction) => {
		const { entrySlugs } = params;
		const { alerts, children } = node;
		const warlordSlugs = [];

		children.forEach(w => {
			const character = w.children.find(c => c.type === "character");
			if (character && character.listEntry.isWarlord) {
				warlordSlugs.push(character.listEntry.slug);
			}
		});
		if (!warlordSlugs.some(s => entrySlugs.includes(s))) {
			const entryNames = entrySlugs.map(s => faction.entries[s].name);
			alerts.push({ message: "One of the following Characters must be selected to be the Warlord: " + entryNames.join(", ") });
		}
	},
	"TEMPERED_SPELLS": (node) => {
		// const { alerts, selections } = node;
		// let fireCount = 0;
		// let earthCount = 0;
		// let magmaCount = 0;
		// let hasOccult = false;
		// let hasHellbringer = false;

		// selections.forEach(s => {
		// 	if (["flame-wall", "fireball", "coruscation"].includes(s)) fireCount += 1;
		// 	else if (["roots-of-stone", "broken-ground", "rock-shaping"].includes(s)) earthCount += 1;
		// 	else if (["eruption", "magmatic-seep", "pyroclast"].includes(s)) magmaCount += 1;
		// 	else if (s === "learned-in-the-occult") hasOccult = true;
		// 	else if (s === "hellbringer-sorcerer") hasHellbringer = true;
		// })

		// if (hasHellbringer && earthCount > 0) {
		// 	alerts.push({ message: "A Hellbringer Sorcerer must select Spells from the Schools of Fire or Magma." });
		// }

		// if (hasOccult) {
		// 	if (
		// 		(fireCount > 1 && earthCount + magmaCount > 1) ||
		// 		(earthCount > 1 && fireCount + magmaCount > 1) ||
		// 		(magmaCount > 1 && earthCount + fireCount > 1) ||
		// 		(magmaCount === 1 && earthCount === 1 && fireCount === 1)
		// 	) {
		// 		alerts.push({ message: "Learned in the Occult allows for one Spell from a different School." });
		// 	}
		// } else {
		// 	if (
		// 		(fireCount > 0 && earthCount + magmaCount > 0) ||
		// 		(earthCount > 0 && fireCount + magmaCount > 0) ||
		// 		(magmaCount > 0 && earthCount + fireCount > 0)
		// 	) {
		// 		alerts.push({ message: "Selected spells must belong to the same School." });
		// 	}
		// }
	},
	"ABERRATION": (node) => {
		const { listEntry, alerts } = node;

		if (listEntry.size > 5) {
			alerts.push({ message: "Aberration requires a Regiment with 5 or fewer Stands." });
		}
	},
	"FINEST_CAVALRY": (node) => {
		const { children, alerts } = node;
		const cavCount = children.filter(c => c.entrySlug === "companion-cavalry").length;
		if (cavCount > 3) {
			alerts.push({ message: "An Ipparchos' Warband may not contain more than three Regiments of Companion Cavalry" });
		}
	},
	"BIOTIC_HIVE": (node) => {
		const { listEntry, alerts } = node;
		const special = listEntry.tagGroups.find(g => g.slug === "special-rules");
		const mutations = listEntry.options.groups.find(g => g.slug === "mutations");
		const hive = mutations && !!mutations.orphans.find(t => t.slug === "biotic-hive").selected;
		const barrage = special && !!special.tags.find(t => t.slug === "barrage");
		if (hive && !barrage) {
			alerts.push({ message: "Biotic Hive can only be applied to Character Stands that already have the Barrage Special Rule." });
		}
	},
	"ONLY_ONE_OF": (node, params) => {
		const { listEntry, alerts } = node;
		const { groups = [] } = params;

		const targets = listEntry.options.groups.filter(g => groups.includes(g.slug));

		function countSelected(group) {
			const countOrphans = group.orphans?.filter(o => o.selected)?.length || 0;
			const countGroups = group.groups?.reduce((prev, cur) => prev + countSelected(cur), 0) || 0;
			return countOrphans + countGroups;
		}

		const count = targets.reduce((prev, cur) => prev + countSelected(cur), 0);

		if (count > 1) {
			alerts.push({ message: `You can only select one of: ${targets.map(t => t.name).join(", ")}` });
		}
	},
	"BOUND_TO_THE_ELEMENTS": (node) => {
		const { listEntry, alerts } = node

		if (node.selections.includes("court-of-fire")) {
			const slugs = ["efreet-flamecasters", "efreet-sword-dancers"];
			if (node.parent.children.filter(c => slugs.includes(c.entrySlug)).length === 0) {
				alerts.push({ message: "A Character from the Court of Fire must have at least one Regiment of Efreet Flamecasters or Efreet Sword Dancers in its Warband." });
			}
		}

		if (node.selections.includes("court-of-air")) {
			const slugs = ["windborne-djinn", "steelheart-djinn"];
			if (node.parent.children.filter(c => slugs.includes(c.entrySlug)).length === 0) {
				alerts.push({ message: "A Character from the Court of Air must have at least one Regiment of Windborne Djinn or Steelheart Djinn in its Warband." });
			}
		}
	},
	"RUTHLESS_SOVEREIGNS": (node) => {
		const leonine = node.children.filter(c => c.entrySlug === "leonine-avatara").length;
		const centaur = node.children.filter(c => c.entrySlug === "centaur-avatara").length;

		if (leonine > 2) {
			node.alerts.push({ message: "This Warband may not contain more than two Regiments of Leonine Avatara" });
		}

		if (centaur > 2) {
			node.alerts.push({ message: "This Warband may not contain more than two Regiments of Centaur Avatara" });
		}
	}
};


export default TLAOK_RULES;
import { Checkbox, FormControlLabel, IconButton, List, ListItem, Paper, Stack, Typography } from "@mui/material";
import LabelledGroup from "../../../ui/containers/LabelledGroup";
import EditIcon from '@mui/icons-material/Edit';
import Reference from "../../../ui/Reference";
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import { useState } from "react";
import FrameDialog from "../../../ui/containers/FrameDialog";
import FrameHeader from "../../../ui/containers/FrameHeader";
import FrameDialogContent from "../../../ui/containers/FrameDialogContent";
import FloatingActions from "../../../ui/FloatingActions";
import QueryIcon from "../../query/QueryIcon";
import { useDispatch } from "react-redux";
import { attachNode, removeNode, updateOptions } from "../../../store/list-slice";
import Section from "../../../ui/containers/Section";
import { useRouteLoaderData } from "react-router-dom";
import { createNode } from "../../../lib/list";
import StepperField from "../../../ui/StepperField";
import { Box } from "@mui/system";


function getSelections(group, selections) {
	const { groups = [], orphans = [] } = group;
	selections.push(...orphans.filter(o => o.selected));
	groups.forEach(g => getSelections(g, selections));
	return selections;
}


function getAllOptionsSlugs(group, slugs) {
	const { groups = [], orphans = [] } = group;
	slugs.push(...orphans.map(o => o.slug));
	groups.forEach(g => getAllOptionsSlugs(g, slugs));
	return slugs;
}



function RetinueOption({ option, totalSelections, maxSelections, onAdd, onRemove }) {
	const { size = 0, slug, name, query, cost } = option;
	const [inputSize, setInputSize] = useState(size);

	function handleAdd() {
		if (totalSelections < maxSelections) {
			setInputSize(prev => prev + 1);
			onAdd();
		}
	}

	function handleRemove() {
		if (inputSize > 0) {
			setInputSize(prev => prev - 1);
			onRemove();
		}
	}


	return (
		<Stack key={slug} direction="row" alignItems="center" spacing={1}>
			<StepperField
				size="small"
				onStepUp={handleAdd}
				onStepDown={handleRemove}
				value={inputSize}
				sx={{
					width: "150px"
				}}
			/>
			<Typography variant="h6">{name}</Typography>
			<Box flexGrow={1}>
				{query && <QueryIcon query={query} />}
			</Box>
			<Typography
				variant="h6"
				align="right"
				pr={1}
			>
				{cost || "Free"}
			</Typography>
		</Stack>
	)
}



function RetinueFB({ node, group }) {
	const { name, slug, groups = [], orphans = [] } = group;
	const [open, setOpen] = useState(false);
	const dispatch = useDispatch();
	const [selectedOptions, setSelectedOptions] = useState(getSelections(group, []));
	const totalSelections = selectedOptions.reduce((prev, o) => prev + o.size || 1, 0);
	const { faction } = useRouteLoaderData("editor");

	const max = slug === "chariot-variants" ? 3 : 1;

	const optionsToAdd = [];
	selectedOptions.forEach(o => {
		for (let i = 0; i < (o.size || 1); i += 1) {
			optionsToAdd.push(o.slug);
		}
	});
	const optionsToRemove = getAllOptionsSlugs(group, []);

	function handleOpen() {
		setOpen(true);
	}

	function handleClose() {
		setOpen(false);
	}

	function handleApply() {
		// dispatch(updateOptions({
		// 	path: node.path,
		// 	add: optionsToAdd,
		// 	remove: optionsToRemove,
		// }));

		// optionsToAdd.forEach(o => {
		// 	if (faction.entries[o]) {
		// 		const officerNode = createNode({
		// 			type: "officer",
		// 			entrySlug: o,
		// 		});

		// 		dispatch(attachNode({
		// 			path: node.path,
		// 			node: officerNode,
		// 		}));
		// 	}
		// });
		dispatch(updateOptions({
			path: node.path,
			add: optionsToAdd,
			remove: optionsToRemove,
		}));

		const retinueNodes = node.children.filter(c => c.type === "retinue");
		retinueNodes.forEach(n => {
			dispatch(removeNode({ parentPath: node.path, nodeId: n.id }));
		})

		optionsToAdd.forEach(o => {
			if (faction.entries[o]) {
				const retinueNode = createNode({
					type: "retinue",
					entrySlug: o,
				});

				dispatch(attachNode({
					path: node.path,
					node: retinueNode,
				}));
			}
		});

		handleClose();
	}

	function handleAddOption(option) {
		setSelectedOptions(prevOptions => {
			const existing = prevOptions.find(o => o.slug === option.slug);
			if (existing) {
				return [
					...prevOptions.filter(o => o.slug !== option.slug),
					{ ...existing, size: (existing.size || 1) + 1 }
				];
			} else {
				return [
					...prevOptions,
					{ ...option, size: 1 }
				]
			}
		});
	}

	function handleRemoveOption(option) {
		setSelectedOptions(prevOptions => {
			const existing = prevOptions.find(o => o.slug === option.slug);
			if (existing && existing.size > 1) {
				return [
					...prevOptions.filter(o => o.slug !== option.slug),
					{ ...existing, size: existing.size - 1 }
				];
			} else if (existing && existing.size <= 1) {
				return [
					...prevOptions.filter(o => o.slug !== option.slug)
				];
			}
		});
	}

	const actions = [
		{
			id: "Apply",
			icon: DoneIcon,
			handler: handleApply
		},
	];

	const content = groups.length ? groups.map(g => (
		<Section key={g.slug} title={`${g.name} (${g.description})`}>
			<Stack spacing={1}>
				{g.orphans.map(o => (
					<RetinueOption
						key={o.slug}
						option={o}
						selectedOptions={selectedOptions}
						totalSelections={totalSelections}
						maxSelections={3}
						onAdd={handleAddOption.bind(null, o)}
						onRemove={handleRemoveOption.bind(null, o)}
					/>))}
			</Stack>
		</Section>
	)) : orphans.map(o => (
		<RetinueOption
			key={o.slug}
			option={o}
			selectedOptions={selectedOptions}
			totalSelections={totalSelections}
			maxSelections={max}
			onAdd={handleAddOption.bind(null, o)}
			onRemove={handleRemoveOption.bind(null, o)}
		/>
	));

	return (
		<>
			<LabelledGroup
				label={name}
				action={
					<IconButton onClick={handleOpen} size="small">
						<EditIcon />
					</IconButton>
				}
			>
				<Stack direction="row" mt={0} flexWrap="wrap" gap={1} py={0.5}>
					{selectedOptions.map(o => (
						<Reference
							key={o.slug}
							label={`${o.name} (${o.size})`}
							query={o.query}
						/>
					))}
				</Stack>
			</LabelledGroup>
			<FrameDialog
				open={open}
				onClose={handleApply}
				responsive
				actions={actions}
			>
				<FrameHeader title={group.name} />
				<FrameDialogContent>
					<Stack spacing={2}>
						{content}
					</Stack>
				</FrameDialogContent>
			</FrameDialog>
		</>
	);
}


export default RetinueFB;